import React, { useState } from 'react';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar
} from '@chatscope/chat-ui-kit-react';

import './App.css'

const fetchChatResponse = async (userInput) => {
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${'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'}` // Your API key should be securely handled
    },
    body: JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{ role: 'user', content: userInput }]
    })
  });

  const data = await response.json();
  if (response.ok) {
    return data.choices[0].message.content; // or handle the full data object as needed
  } else {
    throw new Error(data.error.message || "Failed to fetch response");
  }
};

function App() {
  const initialMessage = {
    message: 'Lets talk about your future career. What is your favourite subject right now?',
    timestamp: new Date().toTimeString().split(" ")[0],
    direction: "incoming",
    avatar: "https://api.multiavatar.com/aibot.svg" // Check this URL
  };

  const [messages, setMessages] = useState([initialMessage]);

  const sendMessage = async (text) => {
    if (text.trim()) {
      const userMessage = {
        message: text,
        timestamp: new Date().toTimeString().split(" ")[0],
        direction: "outgoing",
        avatar: "https://api.multiavatar.com/user.svg" // Check this URL
      };
      const echoMessage = {
        message: text,
        timestamp: new Date().toTimeString().split(" ")[0],
        direction: "incoming",
        avatar: "https://api.multiavatar.com/aibot.svg" // Check this URL
      };

      const response = await fetchChatResponse(text);
      const responseMessage = {
        message: response,
        timestamp: new Date().toTimeString().split(" ")[0],
        direction: "incoming",
        avatar: "https://api.multiavatar.com/aibot.svg" // Check this URL
      };
      setMessages([...messages, userMessage, responseMessage]);
    }
  };

  

  return (
    <div>
      <h2 style={{
        // color: 'var(--primary-color)',
        margin: '0 10px 10px 10px',
        fontWeight: '100',
        fontFamily: 'Poppins',
        fontSize: '1.5em',
        textAlign: 'center',
        paddingTop: '20px'
      }}>
        <span style={{ fontWeight: 800 }}>LIFEGURU</span> Life path options
      </h2>

      <MainContainer style={{ height: "calc(100vh - 70px)", position: "relative", paddingTop: '10px' }}>
        <ChatContainer>
          <MessageList>
            {messages.map((msg, index) => (
              <Message key={index} model={{
                message: msg.message,
                sentTime: msg.timestamp,
                direction: msg.direction,
                className: '.message-'+msg.direction
              }} > 
                <Avatar src={msg.avatar} name="Kai"/>
              </Message>
            ))}
          </MessageList>
          <MessageInput onSend={sendMessage} placeholder="Type message here" />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}

export default App;
